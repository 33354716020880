import { template as template_afd81fc8ea58456bb7d077ca5974d33a } from "@ember/template-compiler";
const SidebarSectionMessage = template_afd81fc8ea58456bb7d077ca5974d33a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
