import { template as template_b82c1a2fb2ee4734b9a457c2729aeba5 } from "@ember/template-compiler";
const FKLabel = template_b82c1a2fb2ee4734b9a457c2729aeba5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
