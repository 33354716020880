import { template as template_dede56406c2e47d49c936c19a6040ba3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_dede56406c2e47d49c936c19a6040ba3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
