import { template as template_8e19d4fc91524454bc1c60ae04b16886 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8e19d4fc91524454bc1c60ae04b16886(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
